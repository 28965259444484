import { db } from './firebase'

import {
  setDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

const COLLECTION = 'invites'

//status: 0 => waiting, 1 => reject , need to resend


export const sendInvite = async (payload) => {
  return await addDoc(collection(db, COLLECTION), payload);
};

export const deleteInvite = async (id) => {
  if (!id) return
  return await deleteDoc(doc(db, COLLECTION, id));
};


export const getInviteByAuthWithFriend = async (from, to) => {
  const col = collection(db, COLLECTION);
  const q = query(col, where("fromUserId", "==", from), where("toUserId", "==", to));
  const snap = await getDoc(q)
  return snap.data()
}


export const getInviteComes = async (to) => {
  const col = collection(db, COLLECTION);
  const q = query(col, where("toUserId", "==", to), where("status", '==', 0));
  const snap = await getDocs(q);
  const list = snap.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }));
  return list;
}

export const getInvitesSent = async (from) => {
  const col = collection(db, COLLECTION);
  const q = query(col, where("fromUserId", "==", from));
  const snap = await getDocs(q);
  const list = snap.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }));
  return list;
}

export const approveInvite = async (invite) => {
  const { id, fromUserId, toUserId } = invite
  //Add to friend
  await addDoc(collection(db, "friends"), {
    friendId: fromUserId,
    userId: toUserId,
    action: ''
  });
  await addDoc(collection(db, "friends"), {
    friendId: toUserId,
    userId: fromUserId,
    action: ''
  });
  //Remove invite
  await deleteDoc(doc(db, COLLECTION, id));
}

export const rejectInvite = async (invite) => {
  const { id, ...rest } = invite
  //Update invite status
  await setDoc(doc(db, COLLECTION, id), {
    ...rest,
    status: 1
  });

}
