<template>
  <div class="flex justify-center items-center q-pa-md" v-if="isLoading">
    <q-spinner color="primary" size="3em" />
  </div>
  <q-list v-if="guest?.length > 0 && !isLoading">
    <q-item style="padding: 20px" clickable v-for="(item, index) in guest" v-ripple :key="index">
      <q-item-section avatar>
        <q-avatar v-if="item.avatar">
          <img :src="item.avatar">
        </q-avatar>
        <q-avatar v-else>
          {{ item.name ? item.name?.slice(0, 2) : '' }}
        </q-avatar>
      </q-item-section>
      <q-item-section style="font-weight: bold;">
        {{ item.name }}
      </q-item-section>
      <q-item-section style="font-weight: bold;" class="items-end">
        <q-btn color="white" size="sm" round text-color="red" icon="o_cloud_sync" v-if="item.status === 1"
          @click="handleSendInvite(item)" />
        <q-btn color="white" size="sm" round v-if="item.status === 0 && !item.inviteId || !item.status"
          icon="o_person_add" @click="handleSendInvite(item)" text-color="primary" />
        <q-btn color="white" size="sm" round v-if="item.status === 0 && item.inviteId" text-color="warning"
          name="o_schedule" />
      </q-item-section>
    </q-item>
    <q-separator inset />
  </q-list>
  <div v-if="guest?.length === 0 && !isLoading">
    <q-text class="flex justify-center q-mt-md" v-if="search === ''">Not found users</q-text>
    <q-text class="flex justify-center q-mt-md" v-else>Not found users! Please check information again.</q-text>
  </div>
</template>

<script setup>
import { toRef, ref, onUnmounted, onMounted, watch } from 'vue'
import { sendInvite, deleteInvite } from '@/services/invites'
import { useAuthStore } from '@/store'
import { useGuest } from '@/composables/useGuest'
import { storeToRefs } from 'pinia'
import { useQuasar } from 'quasar'
import { unsubscribe } from '@/services/users'

const props = defineProps(['search'])
const search = toRef(props, 'search')

const quasar = useQuasar()

const authStore = useAuthStore()
const { _auth } = storeToRefs(authStore)

const { guest, refetch, isLoading } = useGuest(search.value)

onMounted(async () => {
  await refetch()
})

const handleRefetchData = () => {
  refetch(search.value)
}

const handleSendInvite = async (item) => {
  const fromUserId = _auth.value.id
  const fromUserName = _auth.value.name
  const toUserId = item.id
  const status = item.status
  const inviteId = item.inviteId
  if (status === 1 && inviteId) {
    await deleteInvite(inviteId)
  }
  const payload = {
    fromUserId,
    fromUserName,
    toUserId,
    status: 0
  }
  await sendInvite(payload)
  quasar.notify({
    type: 'info',
    message: 'Sent invite successfully!'
  })
  if (inviteId) {
    handleRefetchData()
  }
}

const handleUnsubscribe = unsubscribe(handleRefetchData)

onUnmounted(() => {
  handleUnsubscribe()
})

watch(() => search.value, (newVal) => {
  if (newVal !== '') {
    refetch(newVal)
  }
})

defineExpose({
  refetch: handleRefetchData
})
</script>
