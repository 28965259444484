<template>
  <div class="text-dark">
    <SearchInput @on-search="handleSearch" />
    <ErrorBoundaryWrapper @retry="retryGuest">
      <Guest :search="search" ref="guestRef" />
    </ErrorBoundaryWrapper>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import Guest from '@/components/MakeFriend/Guest.vue';
import SearchInput from '@/components/SearchInput.vue';
import ErrorBoundaryWrapper from '@/components/ErrorBoundaryWrapper/Index.vue'

const search = ref('')

const handleSearch = (val) => {
  search.value = val
}

const guestRef = ref(null)

const retryGuest = () => {
  guestRef?.value?.refetch()
}

</script>
