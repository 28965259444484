import { ref } from 'vue'
import { getUserByQuery } from '@/services/users'
import { useAuthStore, useChatStore } from '@/store'
import { storeToRefs } from 'pinia'
import { getInvitesSent } from '@/services/invites'

const guest = ref([])
const isLoading = ref(false)
const errorMsg = ref(null)

export const useGuest = (search) => {
  
  const fetchUsers = async (val = '') => {
    isLoading.value = true
    const paramSearch = val || search
    const { _auth } = storeToRefs(useAuthStore())
    const { _friends } = storeToRefs(useChatStore())
    const friendIds = [..._friends.value.map(i => i.id), ...[_auth.value.id]]

    const users = await getUserByQuery(paramSearch)
    const guests = users.filter(u => !friendIds.includes(u.id))

    const invites = await getInvitesSent(_auth.value.id)
   
    if (!users || users.length === 0) {
      guest.value = guests
    } else {
      let output = []
      if (invites && invites.length > 0) {
        guests.forEach(u => {
          invites.forEach(i => {
            if (u.id === i.to) {
              output.push({
                ...u, 
                status: i.status,
                inviteId: i.id
              })
            } else {
              output.push({
                ...u, 
                status: null,
                inviteId: i.id
              })
            }
          })
        });
      } else {
        output = guests
      }
      
      guest.value = output.map(o => ({ ...o, name: o.name || o.displayName }));
    }
    isLoading.value = false
  }

  const refetch = (val) => {
    try {
      fetchUsers(val)
    } catch (error) {
      errorMsg.value = error
      guest.value = []
      throw error
    }
  }

  return { guest, refetch, errorMsg, isLoading }
}
